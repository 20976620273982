<template>
	<div id="variantCard">
		<div class="variantManagement_card box-shadow-1" :class="{'activated': !reserve, 'deactivated': reserve}">
			<div class="variantManagement_card__info-col">
				<div><i class="fas fa-seedling"></i>{{ variant?.fullname.toUpperCase() }} <span v-show="reserve" class="deactivated">DEACTIVATED</span></div>
				<div><i class="fas fa-leaf"></i>{{ variant?.cropType.toUpperCase() }}</div>
				<div><i class="fas fa-warehouse"></i>{{ variant.source }}</div>
			</div>
			
			<div class="variantManagement_card__btn-col" v-show="variant.username != cUser">
				<b-button
					@click="activateVariant(variant)"
					size="sm"
					variant="warning"
					v-show="reserve"
				> <i class="fas fa-plus"></i> </b-button>
				<b-button
					@click="deactivateVariant(variant)"
					size="sm"
					variant="danger"
					v-show="!reserve"
				> <i class="fas fa-minus"></i> </b-button>
				<b-button
					v-b-modal.editVariantModal
					@click="setVariantToEdit(variant)"
					size="sm"
					variant="primary"
				> <i class="fas fa-edit"></i> </b-button>
			</div>
		</div>
	
	</div>
	
</template>

<script>
import axios from 'axios';
export default {
	name: "variantCard",
	props : ["variant", "farm"],
	data(){
		return {
			reserve: this.variant.reserved,
			cUser: localStorage.getItem("user")
		}
	},
	methods : {
		setVariantToEdit(variant){
			this.$store.commit('setVarianttoEdit', variant);
		},
		activateVariant(variant){
			axios.put(`/${this.farm}/variantManagement/activate/${variant._id}`, { })
			.then((response) => {
				const res_variant = response.data;
				this.$emit('success', `activated variant ${res_variant._id}`);
			})
			.catch((err) => {
				console.error(err);
				this.$emit('error', err.response.data.error);
			});
		},
		deactivateVariant(variant){
			axios.put(`/${this.farm}/variantManagement/deactivate/${variant._id}`, { })
			.then((response) => {
				const res_variant = response.data;
				this.$emit('success', `deactivated variant ${res_variant._id}`);
			})
			.catch((err) => {
				console.error(err);
				this.$emit('error', err.response.data.error);
			});
		}
	}
}
</script>

<style>
</style>