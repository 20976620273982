<template>
	<div id="variantManagement">
		<div>
            <div id="variantsScreen" class="variantManagement_screen">
                <!--Search bar-->
				<div class="variantScreen__topRow box-shadow-1">
					<input
						v-on:input="debounceInput"
						placeholder="search for variants"
						class="variantScreen__searchInput"
						type="search"
						v-model="searchInput"
					>
					<v-select
						v-on:input="debounceInput"
						class="variantScreen__typeInput"
						v-model="typesInput"
						:options="cropTypes"
						:searchable="true"
						placeholder="Select crop type..."
					/>
					<!-- Check show reserevd variants -->
					<b-form-checkbox size="md" v-model="reserveCheck">
						Show Reserve Variants
					</b-form-checkbox>
					<!-- Add variant icon button -->
					<b-button v-b-modal.addVariantModal size="sm" variant="primary" class="variantScreen__addBtn"> Add Variant </b-button>
				</div>				

                <!-- Card list -->
                <div id="variantList" class="variantManagement_list scroll">
                    <div v-if="variants == null">
                        <CardSkeleton />
                    </div>

                    <div v-else>
                        <div v-for="(variant, vid) in variants" :key="vid"><VariantCard v-show="reserveCheck||!variant.reserved" :variant="variant" :farm="farm" @success="showSuccessToast" @error="showErrorToast" /></div>
                    </div>
                </div>
            </div>
		</div>

		<div>
			<b-toast id="variantMgmt_error" variant="danger" title="Variant Management Error">
				{{ errMsg }}
			</b-toast>
			<b-toast id="variantMgmt_success" variant="primary" title="Variant Management Success">
				{{ sucMsg }}
			</b-toast>
		</div>

		<!--modals / menus-->
		<AddVariantModal :farm="farm" @success="showSuccessToast" @error="showErrorToast" />
		<EditVariantModal :farm="farm" @success="showSuccessToast" @error="showErrorToast" />
	</div>
</template>

<script>
import axios from "axios";
import CardSkeleton from "../components/variantManagement/CardSkeleton.vue";
import VariantCard from "../components/variantManagement/VariantCard.vue";
import AddVariantModal from "../components/variantManagement/AddVariantModal.vue";
import EditVariantModal from "../components/variantManagement/EditVariantModal.vue";
import variantMgmt from '../assets/variantMgmt.json';

export default {
	name : "VariantManagement",
	props: ["farm"],
	data() {
		return {
			variants: null,
			reserveVariants: null,
			reserveCheck: false,
			searchInput: "",
			typesInput: "",
			cropTypes: variantMgmt.types,
			errMsg: "",
			sucMsg: "",
			timeout: null
		}
	},
	methods : {
        getVariants(searchInput){
            this.variants = null;
			axios.get(`/${this.farm}/variantManagement/variants`, { params: { nameQuery: searchInput, typeQuery: this.typesInput }})
			.then((res) => {
				let avariants = res.data.active;
				avariants = avariants.map((v) => {
					v.fullname = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
					v.reserved = false;
					return v;
				});
				let rvariants = res.data.reserve;
				rvariants = rvariants.map((v) => {
					v.fullname = `${v.code || ""} ${v.colour} ${v.name || ""} ${v.group}`;
					v.reserved = true;
					return v;
				});
				this.variants = [...avariants, ...rvariants];
			})
			.catch((error) => {
				console.error(error);
				this.showErrorToast(error, false);
			})
        },
		showErrorToast(msg, rfsh=true){
			this.errMsg = msg;
			this.$bvToast.show('variantMgmt_error');
			if(rfsh){
				this.getVariants();
			}
		},
		showSuccessToast(msg){
			this.sucMsg = msg;
			this.$bvToast.show('variantMgmt_success');
			this.getVariants();
		},
		debounceInput: function() {
			if (this.timeout) clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.getVariants(this.searchInput);
			}, 700);
		}
	},
	mounted(){
        this.getVariants(this.searchInput);
	},
	watch : {
		farm(){
			this.getVariants(this.searchInput);
		}
	},
	destroyed(){
		clearTimeout(this.timeout);
	},
	components : {
        CardSkeleton,
		VariantCard,
		AddVariantModal,
		EditVariantModal
	}
}
</script>

<style>
.variantManagement_screen{
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
	padding: 0px 0px;
}

.variantScreen__topRow{
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	border-radius: 5px;
}

.variantScreen__topRow *{
	margin: 5px 5px;
}

.variantManagement_list {
	max-height: 80vh;
	overflow: auto;
	padding: 10px 20px;
	margin: 10px 0;
}

.variantScreen__typeInput {
	width: 300px;
}

@media only screen and (max-width: 425px) {
	.variantManagement_list {
		padding: 0;
		max-height: none;
		overflow-x: visible;
		overflow-y: visible;
	}
}

.variantManagement_card {
	padding: 10px;
	margin: 20px 0;
	text-align: left;
	border-radius: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;	
}

.variantManagement_card.deactivated {
	background-color: lightgray;
}

.variantManagement_card__info-col {
	display: flex;
	flex-direction: column;
	justify-content: left;
	
	text-overflow: ellipsis;
	white-space: nowrap;
	-moz-white-space: nowrap;
	overflow: hidden;
}

.variantManagement_card__info-col * {
	margin: 2px 5px;
}

.variantManagement_card__info-col .deactivated {
	border-radius: 5px;
	padding: 2px 5px;
	font-family: "courier-new", "monospace";
	color: white;
	background-color: red;
}

.variantManagement_card__btn-col {
	display: flex;
	flex-direction: row;
	justify-content: right;
	align-items: center;
	padding-right: 0;
}

.variantManagement_card__btn-col * {
	margin: 5px 5px 5px 5px;
}

@media only screen and (max-width: 768px) {
	/* For mobile phones: */
	.variantManagement_card {
		margin: 20px 0;
		flex-direction: column;
	}

	.variantManagement_card__btn-col {
		/* display: none; */
		/* flex-direction: column; */
		justify-content: center;
	}

}

.variantManagement__modal {
	display: none;
}

.variantManagement__modal__footer {
	display: flex;
	flex-direction: row;
	justify-content: right;
}

button:focus + .variantManagement__modal {
	display: block;
	background-color: whitesmoke;
	position: absolute;
	width: 69px;
	height: 69px;
	top: 0;
	left : 0;
}

</style>