<template>
	<div class="usermanagement-card-skelly box-shadow-1">
		<div class="usermanagement-card-skelly__inside">

		</div>
	</div>
</template>

<script>
export default {
	name : "CardSkeleton"
}
</script>

<style>
.usermanagement-card-skelly {
	padding: 10px;
	margin: 40px 0;
	text-align: left;
	border-radius: 10px;
}

.usermanagement-card-skelly__inside {
	animation: pulse 2s ease-in-out infinite;
	animation-delay: 0.5s;
	background: #aeaeae no-repeat;
	height: 80px;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

</style>