<template>
	<b-modal
		id="addVariantModal"
		:hide-footer="true"
		:title="`Create a Variant`"
	>
		<p>
			Example: Group: Romaine, Name: Xaroma
		</p>
		<p>
			Variant added will be automatically added to active roster of current farm
		</p>
		<b-form @submit="onUpdateConfirm">
			<b-form-group
				label-cols-sm="4"
				label-cols-lg="3"
				label="Crop Type:"
				label-for="croptype"
			>
				<v-select
					id="croptype"
					:options="cropTypes"
					v-model="form.cropType"
					:clearable="false"
					:searchable="true"
					required
				>
				</v-select>
			</b-form-group>

			<b-form-group
				id="source"
				label="Source:"
				label-for="srcInput"
				label-cols-sm="4"
				label-cols-lg="3"
			>
				<div class="row">
				<div class="col">
					<v-select
						id="srcInput"
						v-if="!otherSource"
						v-model="form.source"
						:clearable="false"
						:searchable="true"
						:options="sources"
						required
					></v-select>
					<b-form-input
						id="srcInput"
						type="text"
						v-else
						v-model="form.source"
						required
					>
					</b-form-input>
				</div>
				<div class="col">
					<input
						class="form-check-input"
						id="source-others"
						v-model="otherSource"
						type="checkbox"
					/>
					<label class="form-check-label" for="source-others">
						Others
					</label>
				</div>
				</div>
			</b-form-group>

			<b-form-group
				id="colour"
				label="Colour:"
				label-for="colourInput"
				label-cols-sm="4"
				label-cols-lg="3"
			>
				<v-select
					id="colourInput"
					v-model="form.colour"
					:clearable="false"
					:searchable="true"
					:options="colours"
					required
				></v-select>
			</b-form-group>

			<b-form-group
				id="group"
				label="Group:"
				label-for="groupInput"
				label-cols-sm="4"
				label-cols-lg="3"
			>
				<b-form-input
					id="groupInput"
					v-model="form.group"
					type="text"
					required
				></b-form-input>
			</b-form-group>

			<b-form-group
				id="name"
				label="Name:"
				label-for="nameInput"
				label-cols-sm="4"
				label-cols-lg="3"
			>
				<b-form-input
					id="nameInput"
					v-model="form.name"
					type="text"
				></b-form-input>
			</b-form-group>

			<b-form-group
				id="code"
				label="Code:"
				label-for="codeInput"
				label-cols-sm="4"
				label-cols-lg="3"
			>
				<b-form-input
					id="codeInpt"
					v-model="form.code"
					type="text"
				></b-form-input>
			</b-form-group>

			<hr />

			<div class="variantManagement__modal__footer">
				<b-spinner v-if="reqStatus" variant="primary"></b-spinner>
				<b-button v-else type="submit" size="sm" variant="primary" :disabled="reqStatus">Submit</b-button>
			</div>
		</b-form>
	</b-modal>
</template>

<script>
import axios from 'axios';
import variantMgmt from '../../assets/variantMgmt.json';
export default {
	name : "AddVariantModal",
	props : {
		farm : {type: String},
	},
	data(){
		return {
			form : {
				colour: "",
				group: "",
				name: "",
				source: "",
				cropType: "",
				code: ""
			},
			otherSource: false,
			colours: variantMgmt.colours,
			sources: variantMgmt.sources,
			cropTypes: variantMgmt.types,
			reqStatus: false
		}
	},
	methods : {
		onUpdateConfirm(e){
			e.preventDefault();
			this.reqStatus = true;
			axios.post(`/${this.farm}/variantManagement/variants`, this.form)
			.then((response) => {
				const res_variant = response.data;
				this.$emit('success', `added variant ${res_variant._id}`);
				this.$bvModal.hide("addVariantModal");
				this.reqStatus = false;
				this.resetForm();
			})
			.catch((err) => {
				console.error(err);
				this.$bvModal.hide("addVariantModal");
				this.$emit('error', err.response.data.error);
				this.reqStatus = false;
				this.resetForm();
			});
		},
		resetForm(){
			this.form = {
				colour: "",
				group: "",
				name: "",
				source: "",
				cropType: "",
				code: "",
				farm: this.farm
			}
		}
	},
}
</script>

<style>
</style>
